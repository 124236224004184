





















































































































































































































































































































































































































































.courseBox {
  display: flex;
  flex-wrap: wrap;
  .form-cell {
    width: 50%;
  }
}
.imgbox {
  .el-form-item__content {
    width: 256px !important;
    height: 156px;
  }
}
.form-inline {
  width: 40%;
}
.custom-tree-node {
  display: flex;
  align-items: center;
  padding: 5px 0;
  .el-input {
    margin-left: 15px;
  }
}
.el-tree-node__content {
  height: auto;
}
.textellipsis {
  width: 30rem;
  /* overflow: auto; */
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}
.el-textarea {
  height: 100px;
  .el-textarea__inner {
    height: 100px !important;
    min-height: 100px !important;
    resize: none;
  }
}
.limitput {
  .el-input__suffix {
    top: 15px;
  }
}
.el-image {
  display: block;
}

.imgBoxs {
  display: flex;
  > img {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
